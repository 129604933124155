@font-face {
  font-family: Open Sans;
  src: url('../assets/fonts/openSans/openSans-light.woff2');
  font-weight: 200;
}

@font-face {
  font-family: Open Sans;
  src: url('../assets/fonts/openSans/openSans-regular.woff2');
  font-weight: 400;
}

@font-face {
  font-family: Open Sans;
  src: url('../assets/fonts/openSans/openSans-medium.woff2');
  font-weight: 500;
}

@font-face {
  font-family: Open Sans;
  src: url('../assets/fonts/openSans/openSans-semiBold.woff2');
  font-weight: 600;
}

@font-face {
  font-family: Open Sans;
  src: url('../assets/fonts/openSans/openSans-bold.woff2');
  font-weight: 700;
}

/* @font-face {
  font-family: Open Sans;
  src: url('../assets/fonts/openSans/openSans-extraBold.ttf');
  font-weight: 800;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --radius: 0.5rem;
  }
}

html {
  scroll-behavior: smooth;

  width: 100vw;
  overflow-x: hidden;
  /* scroll-margin-top: 7rem; */
}

body {
  width: 100vw;
  overflow-x: hidden;
}

/* scroll-behavior: smooth; */
/* scroll-behavior: smooth; */
/* scroll-margin-top: 7rem; */

/* @layer base {
  :root {
    --radius: 0.5rem;
  }
}

*,
html {
  scroll-behavior: smooth !important;
  scroll-margin-top: 7rem;
} */

/* hide scrollbar but allow scrolling */
